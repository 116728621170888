html,
      body {
        padding: 0;
        margin: 0;
        font-family: -apple-system, Open Sans, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        background-color: #003476;
        color: #ffff;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      * {
        box-sizing: border-box;
      }

      main {
        padding: 5rem 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      img.logo {
        height: 100px;
      }

      img.gov {
        height: 50px;
      }

      @media (max-width: 600px) {
        h1 {
          font-size: 1.2rem;
        }
        img.logo {
          height: 60px;
        }
        img.gov {
          height: 30px;
        }
      }

      .container {
        min-height: 100vh;
        padding: 0 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      footer {
        width: 100%;
        height: 100px;
        border-top: 1px solid #00aeef;
        display: flex;
        justify-content: center;
        align-items: center;
      }